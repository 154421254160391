<template>
    <div style="width: 100vw;height: 100vh;min-width: 1030px;min-height: 910px;" class="onsImg">
        <div style="height: 1px;"></div>
        <div class="faCla">
            <div class="fa1">
                <span style="float: left;font-size: 22px;font-weight: 800;">
                    <i class="el-icon-arrow-left"></i>
                </span>
                优酷智能助理
            </div>
            <div class="fa2">
                <div style="height: 1px;"></div>
                <div style="height: 567px;overflow: auto;" class="funCla">

                    <div class="fa3">
                        <div style="text-align: right;height: 100%;">
                            <div class="fa4"
                                style="background: #24A5FF;color: #ffffff; max-width: 85%;margin-right: 9px;padding:3px 16px;">
                                人工客服
                            </div>
                            <img src="@/assets/ykk.png" alt="" srcset=""
                                style="width: 36px;vertical-align: top;border-radius: 50%;margin-right: 8px; ">
                        </div>
                    </div>

                    <div class="fa3">
                        <div style="text-align: left;height: 100%;display: flex;">
                            <!-- <img src="@/assets/qypng.png" alt="" srcset=""
                                style="width: 36px;vertical-align: top;border-radius: 50%;margin-left: 10px;background: #fff;"> -->
                            <div class="fa4"
                                style="background: #fff;color: #000; width: 432px;margin-left: 9px;line-height: 21px; padding: 7px 14px;font-size: 15px;line-height: 22.5px">
                                小主人，以下为您定位 <span style="color: red;font-weight: 800;">热门问题</span>
                                ，您可以直接点击查看解决方案。如无法解决您的问题您可在输出框进行描述，三迷会为您解答哦~
                                <div style="width: 100%;display: flex;flex-wrap: wrap;justify-content: space-between;">
                                    <div class="yueCla">关连续包月</div>
                                    <div class="yueCla">换绑手机</div>
                                    <div class="yueCla">账号禁用</div>
                                    <div class="yueCla">开通会员</div>
                                    <div class="yueCla">忘记账号</div>
                                    <div class="yueCla">会员卡激活</div>
                                    <div class="yueCla">意见反馈</div>
                                    <div class="yueCla">投屏提示购买SVIP</div>
                                </div>
                            </div>
                            <div style="align-self: flex-end;margin: 0px 3px;">
                                <div style="">
                                    <div
                                        style="background: #fff;border-radius: 23px;width: 32px;height: 30px;text-align: center;margin-bottom: 10px;">
                                        <img src="@/assets/zan.png" alt="" srcset=""
                                            style="margin-top: 4px;cursor: pointer;">
                                    </div>
                                    <div
                                        style="background: #fff;border-radius: 23px;width: 32px;height: 30px;text-align: center;">
                                        <img src="@/assets/caic.png" alt="" srcset=""
                                            style="margin-top: 4px;cursor: pointer;">
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <!-- <div class="fa3" style="margin: 12px 0px;">
                        <div style="text-align: right;height: 100%;">
                            <div class="fa4"
                                style="background: #24A5FF;color: #ffffff; max-width: 85%;margin-right: 9px;padding: 1px 10px;">
                                人工客服
                            </div>
                            <img src="@/assets/ykk.png" alt="" srcset=""
                                style="width: 36px;vertical-align: top;border-radius: 50%;margin-right: 8px; ">
                        </div>
                    </div> -->


                    <!-- <div class="fa3">
                        <div style="text-align: left;height: 100%;">
                            <img src="@/assets/qypng.png" alt="" srcset=""
                                style="width: 36px;vertical-align: top;border-radius: 50%;margin-left: 10px;">
                            <div class="fa4"
                                style="background: #fff;color: #000; max-width: 27%;margin-left: 9px;line-height: 21px; padding: 7px 11px;">
                                请您耐心等待不要离线，小艺正在为您安排人工客服接入，稍等片刻。
                            </div>
                        </div>
                    </div> -->

                    <div style="display: flex;align-items: center;justify-content: center;margin-bottom: 25px;">
                        <span style="text-align: center;font-size: 12px;color: #fff;margin-top: 18px;background: #ddd;width: 47px;border-radius: 15px;height: 17px;
    line-height: 18px;">
                            {{ time }}
                        </span>
                    </div>

                    <div style="display: flex;align-items: center;justify-content: center;position: relative;">
                        <div
                            style="text-align: center;font-size: 12px;color: #000;margin-top: 10px;padding: 5px;background: #FFFFFF;width: 243px;height: 79px;">
                            <img src="@/assets/vvPng.png" alt=""
                                style=" width: 40px; position: absolute; top: -12px; background: #fff;border-radius: 33px;left: 48%;">
                            <div style="margin-top: 19px;font-size: 15px;font-weight: 800;">
                                优柳醉
                            </div>
                            <div style="font-size: 14px;color: #666;margin-top: 1px;">
                                人工客服
                            </div>

                        </div>

                    </div>

                    <!-- <div style="display: flex;align-items: center;justify-content: center;">
                        <span
                            style="text-align: center;font-size: 12px;color: #03B435;margin-top: 10px;background: #ECFAEB;padding: 5px;">
                            已接入人工客服
                        </span>
                    </div> -->



                    <div class="fa3">
                        <div style="text-align: left;height: 100%;">
                            <!-- <img src="@/assets/vvPng.png" alt="" srcset=""
                                style="width: 36px;vertical-align: top;border-radius: 50%;margin-left: 10px;background: #fff;"> -->
                            <div class="fa4"
                                style="background: #fff;color: #333; max-width: 55%;margin-left: 9px;line-height: 21px; padding: 9px 13px;">
                                亲，这里是优酷人工客服，请描述您的问题，我们会竭力为您服务~
                            </div>
                        </div>
                    </div>

                    <div class="fa3">
                        <div style="text-align: left;height: 100%;">
                            <!-- <img src="@/assets/vvPng.png" alt="" srcset=""
                                style="width: 36px;vertical-align: top;border-radius: 50%;margin-left: 10px;background: #fff;"> -->
                            <div class="fa4"
                                style="background: #fff;color: #333; max-width: 75%;margin-left: 9px;line-height: 21px; padding: 9px 13px;padding-right: 28px;">
                                优酷两年卡限时四折，SVIP低至0.54元/天，优酷VIP低至0.29元/天。请问您需要详细了解下吗？
                                <span style="display: block;margin-top: 3px;">
                                    <img src="@/assets/meigui.png" alt="" srcset="" style="width: 18px;">
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="fa3">
                        <div style="text-align: left;height: 100%;">

                            <div class="fa4"
                                style="background: #fff;color: #333; max-width: 75%;margin-left: 9px;line-height: 21px; padding: 9px 13px;padding-right: 16px;">
                                亲，您好，请问您是遇到了什么问题呢

                            </div>
                        </div>
                    </div>


                    <div class="fa3" style="margin: 12px 0px;">
                        <div style="text-align: right;height: 100%;">
                            <div class="fa4"
                                style="background: #24A5FF;color: #ffffff; max-width: 85%;margin-right: 9px;padding: 3px 16px;text-align: left; ;">
                                <!-- 你好，请你帮我查下2024年6月24日充值的号吗 15136250351爱奇艺黄金会员有没有充值到账 -->
                                <!-- <el-input v-model="inputText" placeholder=""></el-input> -->
                                <p v-if="inputFla" @click="inputFla = false">{{ inputText || '自定义信息' }}
                                </p>
                                <p v-else>
                                    <el-input v-model="inputText" @keydown.enter="inputFla = true"
                                        @change="inputFla = true" style="width: 100%;"></el-input>
                                </p>

                            </div>
                            <img src="@/assets/ykk.png" alt="" srcset=""
                                style="width: 36px;vertical-align: top;border-radius: 50%;margin-right: 8px; ">
                        </div>
                    </div>

                    <div class="fa3">
                        <div style="text-align: left;height: 100%;">

                            <div class="fa4"
                                style="background: #fff;color: #333; max-width: 75%;margin-left: 9px;line-height: 21px; padding: 9px 13px;padding-right: 16px;">
                                亲，请您稍等2-3分钟分钟，小妹为您去核实一下您的问题哦

                            </div>
                        </div>
                    </div>



                    <div class="fa3" style="margin: 12px 0px;">
                        <div style="text-align: right;height: 100%;">
                            <div class="fa4"
                                style="background: #24A5FF;color: #ffffff; max-width: 85%;margin-right: 9px;padding: 3px 16px;text-align: left; ">
                                <p v-if="inputShop" @click="inputShop = false">{{ inputshop || '自定义信息' }}
                                </p>
                                <p v-else>
                                    <el-input v-model="inputshop" @keydown.enter="inputShop = true"
                                        @change="inputShop = true" style="width: 100%;"></el-input>
                                </p>

                            </div>
                            <img src="@/assets/ykk.png" alt="" srcset=""
                                style="width: 36px;vertical-align: top;border-radius: 50%;margin-right: 8px; ">
                        </div>
                    </div>


                    <div class="fa3">
                        <div style="text-align: left;height: 100%;margin-bottom: 14px;">

                            <div class="fa4"
                                style="background: #fff;color: #333; max-width: 75%;margin-left: 9px;line-height: 21px; padding: 9px 13px;padding-right: 16px;">
                                亲亲，已为您查询，此账号已充值到账
                            </div>
                        </div>
                    </div>

                    <!-- <div class="fa3">
                        <div style="text-align: left;height: 100%;">
                            <img src="@/assets/vvPng.png" alt="" srcset=""
                                style="width: 36px;vertical-align: top;border-radius: 50%;margin-left: 10px;background: #fff;">
                            <div class="fa4"
                                style="background: #fff;color: #000; max-width: 60%;margin-left: 9px;line-height: 21px; padding: 7px 11px;">
                                您好，已为您查询，此账号已充值到账
                            </div>
                        </div>
                    </div> -->

                </div>

                <div style="position: absolute;bottom: 0px;width: 100%;">
                    <div style="display: flex;width: 100%;overflow: hidden;background: #F3F3F4;">
                        <div class="sa1">
                            <img src="@/assets/ttimg.png" alt="" srcset="">
                            发送图片
                        </div>
                        <div class="sa1">
                            <img src="@/assets/ttu.png" alt="" srcset="">
                            结束服务
                        </div>

                    </div>

                    <div style="display: flex;width: 100%;height: 42px;background: ;">

                        <div style="width: 7%;margin-top: 5px;margin-left: 6px;">
                            <img src="@/assets/timg1.png" alt="" srcset="" style="margin-right: 6px;">
                            <img src="@/assets/xiaol4.png" alt="" srcset="" style="vertical-align: super;">
                        </div>

                        <div style="width: 83%;margin-top: 5px;" class="Son1">
                            <el-input v-model="shuru" placeholder="亲，请用一句话描述您的问题"
                                style="width: 100%;background-color: #fff;border-radius: 15px;"></el-input>
                        </div>

                        <div style="margin-top: 5px;" class="fSong">
                            <!-- <img src="@/assets/xiao.png" alt="" srcset="" style="width: 34px;">
                            <img src="@/assets/jia.png" alt="" srcset="" style="width: 34px;"> -->
                            发送
                        </div>


                    </div>

                </div>




            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'kefu',
    data() {
        return {
            time: this.formatDate2(new Date),
            inputText: '',
            inputshop: '',
            inputFla: true,
            inputShop: true,
            shuru: ''
        }
    },

    mounted() {
        console.log(this.formatDate2(new Date));
    },

    methods: {
        formatDate2(now) {
            let year = now.getFullYear(); //取得4位数的年份
            let month = now.getMonth() + 1; //取得日期中的月份，其中0表示1月，11表示12月
            let date = now.getDate(); //返回日期月份中的天数（1到31）
            let hour = now.getHours(); //返回日期中的小时数（0到23）
            let minute = now.getMinutes(); //返回日期中的分钟数（0到59）
            let seconds = now.getSeconds(); //返回日期中的秒数（0到59）
            return (hour <= 9 ? '0' + hour : hour) + ":" + (minute <= 9 ? '0' + minute : minute)
            //  year + "-" + (month <= 9 ? '0' + month : month) + "-" + (date <= 9 ? '0' + date : date) + " " +(hour <= 9 ? '0' + hour : hour) + ":" + (minute <= 9 ? '0' + minute : minute)
            //  + ":" + seconds;
        },
    }
}
</script>

<style lang="less" scoped>
.onsImg {
    background: url(../../assets/BEIJING.png);
    background-size: cover;
}

.faCla {
    width: 960px;
    margin: 8% auto;
    // height: 93.5vh;
    overflow: hidden;
    min-height: 700px;
}

.fa1 {
    height: 45px;
    line-height: 45px;
    background: #EEEEEE;
    font-size: 16px;
    padding-left: 13px;
    color: #000;
    text-align: center;
}

.fa2 {
    background: #F5F5F5;
    // height: 100%;
    height: 650px;
    position: relative;
}

.fa3 {
    min-height: 35px;
    line-height: 35px;
    margin: 0px auto;
    width: 99%;
    margin-top: 12px;

}

.fa4 {
    display: inline-block;
    font-size: 15px;
    width: auto;
    border-radius: 4px;

}

.sa1 {
    color: #000;
    background-color: #fff;
    padding: 3px 10px;
    border-radius: 23px;
    font-size: 12px;
    margin-left: 11px;
    margin-top: 8px;
    margin-bottom: 8px;

    img {
        vertical-align: bottom;
    }
}

.Son1 /deep/ .el-input__inner {
    // background: #F5F7FA;
    color: #F5F5F5;
    border: 1px solid #fff;
    border-radius: 11px;
}

// .Son1 /deep/ .el-input .is-disabled {
//     background: #F5F7FA;
//     color: blue;
//     border: 1px solid #fff;

// }
.Son1 /deep/input::-webkit-input-placeholder {
    color: #9094AF;
    // font-size: 15px;
}


.funCla::-webkit-scrollbar {
    // display: none;
}

.yueCla {
    width: 195px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    background-color: #2DA9FF;
    color: #fff;
    border-radius: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
}

.fSong {
    width: 59px;
    color: #fff;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 15px;
    background-color: #8BCCFA;
    margin-left: 10px;
}
</style>
